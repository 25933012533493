import IDCheck from '@/utils/idcheck'
export default {
  props: {
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    property: {
      immediate: true,
      handler(val) {
        if (this.extraRules) {
          this.rules = Object.assign(this.rules, this.extraRules)
        }
      }
    }
  },
  data() {
    const checkHKID = (rule, value, callback) => {
      // const regIdCard = /^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(\([0−9aA]\)|[0-9aA])$/;
      if (!IDCheck(value)) {
        callback(new Error('Invalid 無效'))
      }
      callback()
    }
    return {
      rules: {
        input: [
          {required: true, message: 'Please enter 請輸入', trigger: ['blur']}
        ],
        select: [
          { required: true, message: 'Please select 請選擇', trigger: [ 'blur','change'] }
        ],
        is_delivery_purpose: [
          { required: true, message: 'Please select 請選擇', trigger: [ 'blur','change'] }
        ],
        is_modification: [
          { required: true, message: 'Please select 請選擇', trigger: [ 'blur','change'] }
        ],
        is_install_camcorder: [
          { required: true, message: 'Please select 請選擇', trigger: [ 'blur','change'] }
        ],
        upload: [
          { required: true, message: 'Please upload 請上傳', trigger: ['blur','change'] }
        ],
        //policy detail
        effective_date:[
          { required: true, message: 'Please select 請選擇', trigger: ['blur','change'] }
        ],
        // motor detail
        registration_mark: [
          // { required: false, trigger: ['blur'] },
          { required: true, message: 'Please enter 請輸入', trigger: ['change', 'blur'] }
        ],
        searing_capacity: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
        ],
        car_make_id: [
          { required: true,  message: 'Please select 請選擇', trigger: [ 'blur','change'] },
        ],
        chassis_number: [
          { required: true, message: 'Please enter 請輸入', trigger: ['blur','change'] },
        ],
        car_model: [
          { required: true, message: 'Please enter 請輸入',  trigger: ['blur','change'] },
        ],
        body_type_id: [
          { required: true, message: 'Please select 請選擇',  trigger: ['blur','change'] },
        ],
        engine_number: [
          { required: true, message: 'Please enter 請輸入', trigger: ['blur','change'] },
        ],
        ncd: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: 'Invalid 無效' ,trigger: [ 'blur','change']},
        ],

        vehicle_cylinder_capacity: [
          { required: true,message:'Please enter 請輸入', trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: 'Invalid 無效',trigger: ['blur' ]},
        ],
        year_of_manufacture: [
          { required: true,  message: 'Please select 請選擇',trigger:  ['blur','change' ] },
        ],
        prev_insurer_nm: [
          { required: true, message: 'Please select 請選擇', trigger: ['blur','change'] }
        ],
        prev_pcy_no: [
          { required: true, message: 'Please enter 請輸入', trigger: ['blur','change'] }
        ],
        pcy_no: [
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        prev_rgtn_no: [
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        car_camcorder_ind: [
          {  required: true, message: 'Please select 請選擇', trigger: ['blur','change'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_title: [
          {  required: true, message: 'Please select 請選擇', trigger: ['blur','change'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        // policy_holder_last_name: [
        //   {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        //   // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        // ],
        // policy_holder_first_name: [
        //   {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        // ],
        policy_holder_birth: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        policy_holder_occ_id: [
          {  required: true, message: this.$t('placeholder.select'), trigger: ['blur','change'] }
        ],


        policy_holder_email:[
          { required: false, message: 'Please enter 請輸入',trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: 'Invalid 無效',trigger: ['blur']  },
        ],
        policy_holder_mobile:[
          { required: true, message: 'Please enter 請輸入', trigger: [ ''] },
          { pattern:/^[23456789]\d{7}$/, message: 'Invalid 無效',trigger: 'blur' }
        ],
        holder_company_name: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        holder_company_id_no: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        holder_company_email: [
          { required: false, message: 'Please enter 請輸入', trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: 'Invalid 無效',trigger: [ 'blur']  },
        ],
        holder_company_phone:[
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] },
          { pattern:/^[23456789]\d{7}$/, message: 'Invalid 無效',trigger: 'blur' }
        ],
        agent_mobile_no:[
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] },
          { pattern:/^[23456789]\d{7}$/, message: 'Invalid 無效',trigger: 'blur' }
        ],
        holder_contact_phone:[
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] },
          { pattern:/^[23456789]\d{7}$/, message: 'Invalid 無效',trigger: 'blur' }
        ],
        holder_contact_email: [
          { required: true, message: 'Please enter 請輸入', trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: 'Invalid 無效',trigger: [ 'blur']  },
        ],
        agent_name: [
          { required: true, message: 'Please enter 請輸入', trigger: [ 'blur'] },
        ],
        policy_holder_pp_no: [
          { required: true, message: 'Please enter 請輸入', trigger: [ 'blur'] },
        ],
        holder_contact_name: [
          { required: true, message: 'Please enter 請輸入', trigger: [ 'blur','change'] },
        ],
        policy_holder_id_type: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
        ],
        client_type: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
        ],
        holder_company_nature_id: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
        ],
      }
    }
  }
}
